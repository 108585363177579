<template>
  <div class="review-page">
    <section class="review container-fluid">
      <div class="container">
        <div class="review-title-block">
          <div class="title">
            <span>{{ $t('review_page.what') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('review_page.description') }}</span>
          </div>
          <div class="review-scroll-button">
            <button>{{ $t('review_page.scroll') }}</button>
          </div>
        </div>
      </div>
    </section>
    <section class="review-list contaiener-fluid">
      <ReviewList />
    </section>
    <section class="smart-trading container-fluid">
      <div class="container">
        <div class="smart-trading-box">
          <div class="title">
            <span>{{ $t('review_page.smart_trading') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('review_page.free_use') }}</span>
          </div>
          <div class="smart-button">
            <button class="start-btn">{{ $t('review_page.start') }}</button>
          </div>
        </div>
      </div>
    </section>
    <section class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('review_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ReviewList from './ReviewList.vue'
export default {
  components: {
    ReviewList,
  },
  mounted() {
    window.scroll(0, 0)
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>

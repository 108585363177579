<template>
  <div class="container">
    <template v-if="paginationReviews">
      <div class="testimonial-list">
        <div
          class="testimonial-card item"
          v-for="(item, i) in listReviews"
          :key="i"
          :class="{ 'medium-item': mediumEls.includes(i) }"
        >
          <div class="item-box">
            <div class="item-header">
              <div class="icon">
                <img src="./img/quote.svg" alt="quote" />
              </div>
              <div class="date">
                <span>10/28/12</span>
              </div>
            </div>
            <div class="item-body">
              <div class="text">
                <span>{{ item.text | intl }}</span>
              </div>
            </div>
            <div class="item-footer">
              <div class="user">
                <div class="user-photo">
                  <!-- <img src="./img/photo_1.svg" alt="Brooklyn" /> -->
                </div>
                <div class="user-data">
                  <span class="name">{{ item.fullName | intl }}</span>
                  <!-- <span class="job">Nursing Assistant</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="testimonial-pagination">
        <div class="pagination-block">
          <div
            class="arrow-left-box"
            v-if="paginationReviews.hasPrevPage"
            @click="toPage(currentPageView - 1)"
          >
            <svg
              width="14"
              height="25"
              viewBox="0 0 14 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.635519 10.9027L10.9552 0.583399C11.6116 -0.0733738 12.6759 -0.0733738 13.3321 0.583399C13.9883 1.23958 13.9883 2.30386 13.3321 2.95999L4.20079 12.091L13.3318 21.2217C13.988 21.8781 13.988 22.9423 13.3318 23.5985C12.6756 24.2549 11.6113 24.2549 10.9549 23.5985L0.635254 13.279C0.307159 12.9508 0.143298 12.521 0.143298 12.091C0.143298 11.6609 0.307479 11.2308 0.635519 10.9027Z"
                fill="#A9C7EF"
              />
            </svg>
          </div>

          <div class="pages-box">
            <div
              class="item"
              v-for="i in totalPages"
              :key="i"
              :class="{ active: currentPageView == i }"
              @click="toPage(i)"
            >
              {{ i }}
            </div>
          </div>

          <div
            class="arrow-right-box"
            v-if="paginationReviews.hasNextPage"
            @click="toPage(currentPageView + 1)"
          >
            <svg
              class="right"
              width="14"
              height="25"
              viewBox="0 0 14 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.635519 10.9027L10.9552 0.583399C11.6116 -0.0733738 12.6759 -0.0733738 13.3321 0.583399C13.9883 1.23958 13.9883 2.30386 13.3321 2.95999L4.20079 12.091L13.3318 21.2217C13.988 21.8781 13.988 22.9423 13.3318 23.5985C12.6756 24.2549 11.6113 24.2549 10.9549 23.5985L0.635254 13.279C0.307159 12.9508 0.143298 12.521 0.143298 12.091C0.143298 11.6609 0.307479 11.2308 0.635519 10.9027Z"
                fill="#A9C7EF"
              />
            </svg>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <UiPreloader />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    paginationReviews: undefined,

    limitView: 9,
    currentPageView: 1,

    mediumEls: [1, 4, 7],
  }),

  computed: {
    listReviews() {
      return this.paginationReviews?.docs || []
    },

    totalPages() {
      return this.paginationReviews?.totalPages || 1
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('Reviews', ['getReviews']),

    getData() {
      this.getReviews({
        limit: this.limitView,
        page: this.currentPageView,
        sort: { createdAt: -1 },
      }).then(data => {
        this.paginationReviews = data
      })
    },

    toPage(page) {
      this.currentPageView = page

      this.getData()
    },
  },
}
</script>
